import React from "react"

import styles from "../../styles/main.module.css"

export default function EpictetusDiscourseChaps9And11() {
    return (
      <React.Fragment>
        <h2>Chapters Nine and Eleven</h2>
        <hr className={styles.top_seperator} />
        <p>As part of my reading group with my friend <a href="http://edgarlin.blog/">Edgar's</a> reading group on ethics we are exploring Stoicism. Currently we are focusing on Epictetus and I'm thinking of writing down some of my thoughts after doing the readings to help me think and remember what I actually thought when we meet to talk. Posting publically to get into the habit of posting.</p>
        <h3>What's He Saying?</h3>
        <p>In Chapter nine he seems to be talking about accepting what your situation is in life. He notes how a lot of people desire and complain, but they could be happy if they changed their mindset. This is most apparent in the last two paragraphs (a man “is not miserable… through the means of another”). In the previous sections he seems to be responding to a response which he may receive on occasion which asks “why not just commit suicide?” though I’m not sure what this response is trying to say or what its responding to. Maybe I misunderstood and he’s talking more about the “suffering” that Buddhists also talk about (i.e. the discussion about even the lowliest creatures finding food is meant to allay your fears so that you won’t use them as an excuse to suffer, and so that you can instead focus on understanding that it is suffering which you are experiencing, not something justified).</p>
        <p>The end of chapter ten was certainly more straightforward than the convoluted argument about whether it was natural or not (and good or not) to abandon his daughter. They obviously decided it was not good (of course he required others not to leave her and in more general situations not do the whole abandonment thing, so it would be absurd to consider him right in doing it himself). The paragraph on what moves us makes sense. I think it’s just a continuation of what we saw in chapter nine. He discusses how we are able to control our reactions and our actions and so we should focus on that and “nothing else” to be righteous. Of course, to succeed here we need to examine our opinions (says Epictetus), which is actually a nice take since he’s not forcing his own morality down our throats. Instead, he lets us figure out what we think is right or wrong. He just wants us to be disciplined in thinking about our own values and living according to them, not against them. Most people have, generally, similar values, so this will tend to be a good strategy for human coexistence.</p>
        <p>Regarding the long, unclear paragraph. The discussion on cuisines of various cultures seems to be to be an attempt at a proof that “ignorance” exists regarding right and wrong (at least in some broad sense) since there is disagreement and they can’t all be right. Following this proof he posits that we should examine where our ignorance (or just ignorance in general) lies. The next section is Epictetus “lending a hand” to this dude. He first lays out that rationality is in line with good and that affection is good and natural. Then we explore whether him abandoning his child is consistent with affection. We then go through others who are affectionate and see whether affectionate people should abandon those they are affectionate towards and see that obviously no, so it’s not consistent to abandon (etc). These sorts of “proofs” that these ancients provide are actually pretty annoying in their linguistic presentation (also you can usually see how the proof will work if you know the axioms, so let’s just lay them out and show the proof, then let’s show why the axioms should be right instead of doing this sort of interleaving action).</p>
        <h3>Is it Any Good?</h3>
        <p>I think on a broad level his philosophy seems pretty reasonable. Your unhappiness comes as much from your unhappy reaction as the source of unhappiness. With sufficient mental gymnastics and/or training I can avoid being unhappy over events that would make normal people unhappy since you need both the lock and the key to open the unhappiness box (i.e. think of always thinking on the "bright side" as a way to change yourself and not the exterior world to change your response to certain experiences). There are also elements of "life goes on" and "if life ends it was meant to end by god" mixed in with the whole first person experience matters on the first person (internal experience) as much as on the external experience. I don't particularly like his style, just as I don't like the style of most of the ancients. It's half logic half rhetorical. I personally prefer stories with morals and then you show the logic... then you iterate on the axioms until we see that they obviously make sense.</p>
        <div className={styles.phone_home}>
          <a href="/">Click me to return home.</a>
        </div>
      </React.Fragment>
    )
}
